<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    labelSize: {
      type: Number,
      default: null
    }
  },
  methods: {
    handleChange(value) {
      this.$emit('update', value)
    }
  }
}
</script>

<template>
  <div
    class="base-checkbox"
    style="
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    "
  >
    <div
      v-if="label"
      class='input-label'
      style="
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
      "
    >
      <label
        style="
          color: #333;
          font-weight: bold;
          position: relative;
          padding-right: 24px;
        "
        :style="'font-size: ' + (labelSize ? labelSize : 14) + 'px'"
      >
        {{ label }}
      </label>

      <el-checkbox
        :disabled="disabled"
        v-bind="$attrs"
        v-on="$listeners"
        size="medium"
        @change="handleChange"
      />
    </div>
  </div>
</template>

<style lang="scss">
.base-checkbox {
  .el-checkbox__inner {
    box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
  }
}
</style>

import request from '@/utils/request'
import serialize from '@/utils/serialize'
import _ from 'lodash'

export function getContest(query) {
  return request({
    url: '/t-rcv-contest/contests/detail' + serialize(query),
    method: 'get'
  })
}

export function listContest(query) {
  return request({
    url: '/t-rcv-contest/contests' + serialize(query),
    method: 'get'
  })
}

export function createContest(data) {
  return request({
    url: '/t-rcv-contest/contests',
    method: 'post',
    data
  })
}

export function updateContest(data) {
  return request({
    url: '/t-rcv-contest/contests',
    method: 'put',
    data
  })
}

export function deleteContest(data) {
  return request({
    url: '/t-rcv-contest/contests',
    method: 'delete',
    data
  })
}

export function listQuestion(contestId) {
  return request({
    url: '/t-rcv-contest/questions/' + contestId,
    method: 'get'
  })
}

export function createQuestion(data) {
  return request({
    url: '/t-rcv-contest/questions',
    method: 'post',
    data
  })
}

export function updateQuestion(data) {
  return request({
    url: '/t-rcv-contest/questions',
    method: 'put',
    data
  })
}

export function deleteQuestion(data) {
  return request({
    url: '/t-rcv-contest/questions',
    method: 'delete',
    data
  })
}

export function uploadAttachment(data) {
  const upload = new FormData()
  upload.append('file', data.file)

  const query = _.pick(data, [
    'contestId'
  ])

  return request({
    url: '/t-rcv-contest/questions/upload' + serialize(query),
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function createContestRankings(contestId) {
  return request({
    url: '/t-rcv-contest/rankings/' + contestId,
    method: 'post'
  })
}

export function getTeams(data) {
  return request({
    url: '/t-rcv-contest/teams/' + data.contestId + serialize(data.query),
    method: 'get'
  })
}

export function deleteTeam(data) {
  return request({
    url: '/t-rcv-contest/teams',
    method: 'delete',
    data
  })
}

export function createTeam(data) {
  return request({
    url: '/t-rcv-contest/teams',
    method: 'post',
    data
  })
}

export function updateTeam(data) {
  return request({
    url: '/t-rcv-contest/teams',
    method: 'put',
    data
  })
}

export function getParticipants(data) {
  return request({
    url: '/t-rcv-contest/participants/' + serialize(data.query),
    method: 'get'
  })
}

export function deleteParticipant(data) {
  return request({
    url: '/t-rcv-contest/participants',
    method: 'delete',
    data
  })
}

export function createParticipant(data) {
  return request({
    url: '/t-rcv-contest/participants',
    method: 'post',
    data
  })
}

export function updateParticipant(data) {
  return request({
    url: '/t-rcv-contest/participants',
    method: 'put',
    data
  })
}

export function importParticipants(data) {
  return request({
    url: '/t-rcv-contest/participants/import',
    method: 'put',
    data
  })
}

export function getParticipantRankings(data) {
  return request({
    url: '/t-rcv-contest/results/participant-rankings/' + serialize(data.query),
    method: 'get'
  })
}

export function getTeamRankings(data) {
  return request({
    url: '/t-rcv-contest/results/team-rankings/' + serialize(data.query),
    method: 'get'
  })
}

export function getStatistics(data) {
  return request({
    url: '/t-rcv-contest/results/statistics/' + serialize(data.query),
    method: 'get'
  })
}

import FormData from 'form-data'
import axios from 'axios'

import request from '@/utils/request'

const CancelToken = axios.CancelToken

export function uploadLessonVideo(data, handleProgress, cancel) {
  const upload = new FormData()
  upload.append('file', data.file)

  return request({
    url: `/lessons/upload-video?${data.query}`,
    method: 'post',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: handleProgress,
    cancelToken: new CancelToken(function executor(c) {
      cancel(c)
    })
  })
}

export function createSubmissionPresignedUrl(data) {
  if (!data.file) {
    throw new Error('E_FILE_INVALID')
  }

  const body = {
    classId: data.classId,
    filename: data.file.name,
    contentType: data.file.type,
    fileSize: data.file.size
  }

  return request({
    url: '/homework/create-upload-url',
    method: 'post',
    data: body
  })
}

export function uploadUsingPreSignedUrl(presignedData, file, handleProgress, cancel) {
  return new Promise((resolve, reject) => {
    file.arrayBuffer().then(buffer => {
      axios({
        url: presignedData.url,
        method: 'put',
        timeout: false,
        data: buffer,
        headers: presignedData.headers,
        onUploadProgress: handleProgress,
        responseType: 'document',
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        cancelToken: new CancelToken(function executor(c) {
          if (cancel) {
            cancel(c)
          }
        })
      }).then(data => {
        resolve(data)
      }).catch(reject)
    }).catch(reject)
  })
}

const state = {
  matchingDrawlerLines: []
}

const mutations = {
  SET_MATCHING_DRAWER_LINES: (state, data) => {
    if (state.matchingDrawerLines && state.matchingDrawerLines.length) {
      // erase the previous matchingDrawerLines
      state.matchingDrawerLines.forEach((line) => {
        line.remove()
      })
    }
    state.matchingDrawerLines = data
  }
}

const actions = {
  setMatchingDrawerLines({ commit }, data) {
    commit('SET_MATCHING_DRAWER_LINES', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

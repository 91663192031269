<script>
export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },

    icon: {
      type: String
    },

    customStyle: {
      type: String,
      default: ''
    },

    color: {
      type: String,
      default: '#1CAB36'
    },

    isActivated: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<template>
  <div
    class="base-filter-button"
    :style="`
      color: ${isActivated ? '#fff' : color};
      border: 2px solid ${color};
      background-color: ${isActivated ? color : 'transparent'};
      ${customStyle}
    `"
    @click="$emit('click')"
  >
    <span v-if="label"> {{ label }} </span>
    <md-icon
      v-else
      :style="`
        color: ${isActivated ? '#fff' : color};
        background-color: ${isActivated ? color : 'transparent'};
        ${customStyle}
      `"
      style="font-size: 20px; height: 20px"
    > {{ icon }} </md-icon>
  </div>
</template>

<style lang="scss">
.base-filter-button {
  cursor: pointer;
  display: inline-flex;
  padding: 2px 16px;
  border-radius: 6px;
  font-weight: bold;
  font-size: 12px;
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

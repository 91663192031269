import * as API from '@/api/document'

const state = {
  categories: [],
  subjects: []
}

const mutations = {
  SET_CATEGORIES: (state, data) => {
    state.categories = data
  },

  SET_SUBJECTS: (state, data) => {
    state.subjects = data
  }
}

const actions = {
  GetSubjects({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getSubjects(data).then(response => {
        commit('SET_SUBJECTS', response.data.subjects)
        resolve(response.data)
      }).catch(reject)
    })
  },

  GetCategories({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getCategories(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  GetCategory({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getCategory(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  QuickShareFile({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.quickShareFile(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"base-filter-button",style:(`
    color: ${_vm.isActivated ? '#fff' : _vm.color};
    border: 2px solid ${_vm.color};
    background-color: ${_vm.isActivated ? _vm.color : 'transparent'};
    ${_vm.customStyle}
  `),on:{"click":function($event){return _vm.$emit('click')}}},[(_vm.label)?_c('span',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_c('md-icon',{staticStyle:{"font-size":"20px","height":"20px"},style:(`
      color: ${_vm.isActivated ? '#fff' : _vm.color};
      background-color: ${_vm.isActivated ? _vm.color : 'transparent'};
      ${_vm.customStyle}
    `)},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
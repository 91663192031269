<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: 'Cập nhật'
    },
    formData: {
      type: Object,
      default: () => {}
    },
    fields: {
      type: Array,
      default: () => {}
    },
    cancelButton: {
      type: String,
      default: 'Huỷ'
    }
  },
  data() {
    return {
      activeTemp: false
    }
  },
  watch: {
    active: {
      handler(val) {
        this.activeTemp = val
      },
      immediate: true
    }
  },
  computed: {
    item: function() {
      return Object.assign({}, this.formData)
    }
  }
}
</script>

<template>
  <div
    class="base-form-dialog"
  >
    <md-dialog
      :md-active.sync="activeTemp"
      @md-closed="$emit('close')"
      @md-clicked-outside="$emit('close')"
    >
      <md-dialog-title>{{ title }}</md-dialog-title>
      <md-dialog-content>
        <md-field v-for="(f, ind) in fields" :key="ind">
          <label>{{f.title}}</label>
          <md-input v-if="f.type == 'text'" v-model="item[f['name']]" />
        </md-field>
      </md-dialog-content>
      <md-dialog-actions>
        <md-button class="md-danger" @click.prevent="$emit('cancel')">{{ cancelButton }}</md-button>
        <md-button class="md-primary" @click.prevent="$emit('confirm', item)">{{ $t('SAVE') }}</md-button>
      </md-dialog-actions>
    </md-dialog>
  </div>
</template>

import * as API from '@/api/discussion'
import _ from 'lodash'

const state = {
  topics: [],
  comments: []
}

const mutations = {
  SET_TOPICS: (state, data) => {
    state.topics = data
  },

  ADD_TOPIC: (state, data) => {
    state.topics.unshift(data)
  },

  UPDATE_TOPIC: (state, data) => {
    const index = _.findIndex(state.topics, (topic) => {
      return topic._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.topics.splice(index, 1, data)
    }
  },

  DELETE_TOPIC: (state, data) => {
    const index = _.findIndex(state.topics, (topic) => {
      return topic._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      return state.topics.splice(index, 1)
    }
  },

  SET_COMMENTS: (state, data) => {
    state.comments = data
  },

  ADD_COMMENT: (state, data) => {
    state.comments.unshift(data)
  },

  UPDATE_COMMENT: (state, data) => {
    const index = _.findIndex(state.comments, (comment) => {
      return comment._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.comments.splice(index, 1, data)
    }
  },

  DELETE_COMMENT: (state, data) => {
    const index = _.findIndex(state.comments, (comment) => {
      return comment._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      return state.comments.splice(index, 1)
    }
  }
}

const actions = {
  getTopics({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getTopics(data)
        .then(response => {
          commit('SET_TOPICS', response.data.topics)
          resolve()
        })
        .catch(reject)
    })
  },
  createTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createTopic(data)
        .then(response => {
          commit('ADD_TOPIC', response.data.topic)
          resolve()
        })
        .catch(reject)
    })
  },
  updateTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateTopic(data)
        .then(response => {
          commit('UPDATE_TOPIC', response.data.topic)
          resolve()
        })
        .catch(reject)
    })
  },
  deleteTopic({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteTopic(data)
        .then(response => {
          commit('DELETE_TOPIC', response.data.topic)
          resolve()
        })
        .catch(reject)
    })
  },
  getComments({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.getComments(data)
        .then(response => {
          commit('SET_COMMENTS', response.data.comments)
          resolve()
        })
        .catch(reject)
    })
  },
  createComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.createComment(data)
        .then(response => {
          commit('ADD_COMMENT', response.data.comment)
          resolve()
        })
        .catch(reject)
    })
  },
  updateComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.updateComment(data)
        .then(response => {
          commit('UPDATE_COMMENT', response.data.comment)
          resolve()
        })
        .catch(reject)
    })
  },
  deleteComment({ commit }, data) {
    return new Promise((resolve, reject) => {
      API.deleteComment(data)
        .then(response => {
          commit('DELETE_COMMENT', response.data.comment)
          resolve()
        })
        .catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

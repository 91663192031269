import * as API from '@/api/live-exam'

const state = {
  liveExam: null,
  result: []
}

const mutations = {
  SET_LIVE_EXAM: (state, data) => {
    state.liveExam = data
  },

  SET_RESULT: (state, data) => {
    state.result = data
  }
}

const actions = {
  getLiveExam({ commit }, query) {
    return API.getLiveExam(query).then(res => {
      commit('SET_LIVE_EXAM', res.data?.liveExam)
    })
  },

  getLiveExamResult({ commit }, query) {
    return API.getLiveExamResult(query).then(res => {
      commit('SET_RESULT', res.data?.result)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import request from '@/utils/request'
import serialize from '@/utils/serialize'
import _ from 'lodash'

export function getContest(query) {
  return request({
    url: '/rcv-contest/contests/detail' + serialize(query),
    method: 'get'
  })
}

export function listContest(query) {
  return request({
    url: '/rcv-contest/contests' + serialize(query),
    method: 'get'
  })
}

export function createContest(data) {
  return request({
    url: '/rcv-contest/contests',
    method: 'post',
    data
  })
}

export function updateContest(data) {
  return request({
    url: '/rcv-contest/contests',
    method: 'put',
    data
  })
}

export function deleteContest(data) {
  return request({
    url: '/rcv-contest/contests',
    method: 'delete',
    data
  })
}

export function listQuestion(contestId) {
  return request({
    url: '/rcv-contest/questions/' + contestId,
    method: 'get'
  })
}

export function createQuestion(data) {
  return request({
    url: '/rcv-contest/questions',
    method: 'post',
    data
  })
}

export function updateQuestion(data) {
  return request({
    url: '/rcv-contest/questions',
    method: 'put',
    data
  })
}

export function deleteQuestion(data) {
  return request({
    url: '/rcv-contest/questions',
    method: 'delete',
    data
  })
}

export function uploadAttachment(data) {
  const upload = new FormData()
  upload.append('file', data.file)

  const query = _.pick(data, [
    'contestId'
  ])

  return request({
    url: '/rcv-contest/questions/upload' + serialize(query),
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function getContestParticipants(data) {
  return request({
    url: '/rcv-contest/participants/' + data.contestId + serialize(data.query),
    method: 'get'
  })
}

export function createContestRankings(contestId) {
  return request({
    url: '/rcv-contest/rankings/' + contestId,
    method: 'post'
  })
}

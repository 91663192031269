import request from '@/utils/request'
import serialize from '@/utils/serialize'

export function getTopics(data) {
  return request({
    url: '/discussion/topics' + serialize(data),
    method: 'get'
  })
}

export function createTopic(data) {
  return request({
    url: '/discussion/topics',
    method: 'post',
    data
  })
}

export function updateTopic(data) {
  return request({
    url: '/discussion/topics',
    method: 'put',
    data
  })
}

export function deleteTopic(data) {
  return request({
    url: '/discussion/topics' + serialize(data),
    method: 'delete'
  })
}

export function getComments(data) {
  return request({
    url: '/discussion/comments' + serialize(data),
    method: 'get'
  })
}

export function createComment(data) {
  return request({
    url: '/discussion/comments',
    method: 'post',
    data
  })
}

export function updateComment(data) {
  return request({
    url: '/discussion/comments',
    method: 'put',
    data
  })
}

export function deleteComment(data) {
  return request({
    url: '/discussion/comments' + serialize(data),
    method: 'delete'
  })
}

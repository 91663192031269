<script>
import Tinymce from '@/components/Tinymce'

export default {
  components: {
    Tinymce
  },
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    disabled: {
      type: Boolean,
      default: null
    },
    label: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      editDialog: false,
      loading: false,
      val: this.$attrs.value
    }
  },
  methods: {
    handleChange(event) {
      this.val = event
    },
    handleOpenDialog() {
      this.val = this.$attrs.value
      this.editDialog = true
    },
    handleSave() {
      this.$emit('update', this.val)
      this.editDialog = false
    },
    handleDelete() {
      this.$emit('clickSuffixIcon', this.val)
      this.editDialog = false
    },
    handleClose() {
      this.editDialog = false
    }
  }
}
</script>

<template>
  <div
    class="base-question-rich-text"
    style="
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    "
  >
    <div
      v-if="label"
      class='input-label'
      style="
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
      "
    >
      <label
        style="
          color: #333;
          font-weight: bold;
          position: relative;
        "
      >
        {{ label }}
      </label>
    </div>

    <md-field>
      <div class="md-has-rich-text" style="width: 100%;" v-html="$attrs.value"></div>
      <div v-if="!disabled" class="settings-btn" @click="handleOpenDialog">
        <span class="settings-circle">
          <md-icon>settings</md-icon>
        </span>
      </div>
    </md-field>
    <md-dialog
      :md-active.sync="editDialog"
      class="question-group-dialog"
    >
      <md-dialog-title>
        <div class="title">{{ label }}</div>
        <div style="display: flex;">
          <BaseButton
            :styleButton="{background: '#ff5252'}"
            :label="$t('DELETE')"
            @click="handleDelete"
          />
          <BaseButton
            :label="$t('SAVE')"
            @click="handleSave"
          />
          <BaseButton
            :styleButton="{background: '#ccc'}"
            :label="$t('CLOSE')"
            @click="handleClose"
          />
        </div>
      </md-dialog-title>

      <md-dialog-content v-loading="loading">
        <div class="input-field">
          <Tinymce
            class="rich-text"
            :value="val"
            v-on="$listeners"
            @input="handleChange"
            :height="400"
          />
        </div>
      </md-dialog-content>
    </md-dialog>
  </div>
</template>

<style lang="scss">
.input {
  background: rgba(0, 0, 0, 0.05);
  // box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
  border: 1px solid transparent !important;
  border-radius: 5px;
  margin: 0 !important;
  width: 100% !important;
  height: 42px !important;
}

  .base-question-rich-text {
    .md-field {
      margin: 0 !important;
      padding: 0 !important;

      .md-has-rich-text {
        line-height: 48px;
        border: 1px solid transparent !important;
      }
    }

    .rich-text {
      background: rgba(0, 0, 0, 0.05) !important;
      border-radius: 5px !important;
    }

    .settings-btn {
      display: flex;
      align-items: center;
      color: #1CAB36;
      cursor: pointer;
      padding-bottom: 12px;

      .label {
        font-weight: bold;
      }

      .settings-circle {

      }
    }
  }
</style>

import { uploadLessonVideo, createSubmissionPresignedUrl } from '@/api/media'
import { createHomeworkCommentUploadUrl } from '@/api/content'

const progress = commit => {
  return progressEvent => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
    commit('UPDATE_UPLOAD_PROGRESS', percentCompleted)
  }
}

const cancel = commit => {
  return c => {
    commit('GET_CANCEL_UPLOAD', c)
  }
}

const state = {
  uploadProgress: 0,
  cancelUpload: () => {},
  drive: null
}

const mutations = {
  UPDATE_UPLOAD_PROGRESS: (state, percentCompleted) => {
    if (percentCompleted !== state.uploadProgress) {
      state.uploadProgress = percentCompleted
    }
  },
  GET_CANCEL_UPLOAD: (state, c) => {
    state.cancelUpload = c
  },
  CANCEL_UPLOAD: (state) => {
    state.cancelUpload()
  },
  SET_GOOGLE_DRIVE_DATA: (state, data) => {
    state.googleDrive = data
  },
  SET_DRIVE_DATA: (state, data) => {
    state.drive = data
  }
}

const actions = {
  ResetUploadProgress({ commit }) {
    commit('UPDATE_UPLOAD_PROGRESS', 0)
  },
  UploadLessonVideo({ commit }, data) {
    return new Promise((resolve, reject) => {
      uploadLessonVideo(data, progress(commit), cancel(commit)).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  CreateSubmissionPreSignedUploadUrl({ commit }, data) {
    return new Promise((resolve, reject) => {
      createSubmissionPresignedUrl(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  },
  CreateCommentPreSignedUploadUrl({ commit }, data) {
    return new Promise((resolve, reject) => {
      createHomeworkCommentUploadUrl(data).then(response => {
        resolve(response.data)
      }).catch(reject)
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

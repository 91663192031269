import axios from 'axios'
import get from 'lodash/get'

import store from '@/store'
import { getToken } from '@/utils/auth'
import { Notification } from 'element-ui'

// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  timeout: 25000
})

// request interceptor
service.interceptors.request.use(
  config => {
    if (store.getters.token) {
      config.headers.Authorization = 'Bearer ' + getToken()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(error)
    }

    Notification({
      type: 'error',
      title: 'Error',
      message: get(error, 'response.data.error.msg') || error.message
    })

    return Promise.reject(error)
  }
)

export default service

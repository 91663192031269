import { login, logout, getProfile, update, listTeacher, createTeacher, updateTeacher, changeAvatar } from '@/api/user'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { changeLogo, updateSchool } from '@/api/school'
import _ from 'lodash'

const state = {
  teachers: [],
  token: getToken(),
  profile: '',
  orgCategory: '',
  roles: []
}

const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_PROFILE: (state, profile) => {
    state.profile = profile
  },
  SET_ORGCATEGORY: (state, orgCategory) => {
    state.orgCategory = orgCategory
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles
  },
  SET_TEACHERS: (state, data) => {
    state.teachers = data
  },
  ADD_TEACHER: (state, data) => {
    state.teachers.unshift(data)
  },
  UPDATE_TEACHER: (state, data) => {
    // const index = _.findIndex(state.teachers, (eTeacher) => {
    //   return eTeacher._id.toString() === data._id.toString()
    // })

    // if (index !== -1) {
    //   if (data.isActivated !== state.teachers[index]) {
    //     state.teachers.splice(index, 1)
    //   } else {
    //     state.teachers.splice(index, 1, data)
    //   }
    // }
  }
  // DELETE_TEACHER: (state, data) => {
  //   const index = _.findIndex(state.classes, (eClass) => {
  //     return eClass._id.toString() === data._id.toString()
  //   })
  //
  //   if (index !== -1) {
  //     return state.classes.splice(index, 1)
  //   }
  // },
}

const actions = {
  login({ commit }, userInfo) {
    const { username, password } = userInfo
    return new Promise((resolve, reject) => {
      login({ username: username.trim(), password: password }).then(response => {
        commit('SET_TOKEN', response.data.accessToken)
        setToken(response.data.accessToken)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  logout({ commit }) {
    return new Promise((resolve, reject) => {
      logout().then(response => {
        commit('SET_TOKEN', undefined)
        removeToken()
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  update({ commit }, userInfo) {
    const { displayName, phone, contact } = userInfo
    return new Promise((resolve, reject) => {
      update({ displayName: displayName, phone: phone, contact: contact }).then(response => {
        state.profile.displayName = displayName
        state.profile.phone = phone
        commit('SET_PROFILE', state.profile)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  getInfo({ commit, state }) {
    return new Promise((resolve, reject) => {
      getProfile(state.token).then(response => {
        const data = response.data

        if (!data) {
          reject(new Error('Xác thực thất bại, vui lòng đăng nhập lại.'))
        }
        data.profile.school = data.profile.school || data.school
        data.profile.group = data.group
        const { roles, googleDrive } = data.profile
        // roles must be a non-empty array
        // if (!roles || roles.length <= 0) {
        //   reject(new Error('Tài khoản không có quyền truy cập!'))
        // }

        commit('SET_ROLES', roles)
        commit('SET_PROFILE', data.profile)
        commit('SET_ORGCATEGORY', data.orgCategory)
        commit('media/SET_GOOGLE_DRIVE_DATA', googleDrive, { root: true })
        resolve(data.profile)
      }).catch(error => {
        reject(error)
      })
    })
  },

  getListTeacher({ commit }, data) {
    return new Promise((resolve, reject) => {
      listTeacher(data).then(response => {
        commit('SET_TEACHERS', response.data.teachers)
        resolve(response)
      }).catch(error => {
        reject(error)
      })
    })
  },

  createTeacher({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      createTeacher(userInfo).then(response => {
        commit('ADD_TEACHER', response.data.teacher)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateTeacher({ commit }, userInfo) {
    return new Promise((resolve, reject) => {
      updateTeacher(userInfo).then(response => {
        commit('UPDATE_TEACHER', response.data.teacher)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  // remove token
  resetToken({ commit }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_ROLES', [])
      removeToken()
      resolve()
    })
  },

  updateAvatar({ commit }, data) {
    return new Promise((resolve, reject) => {
      changeAvatar(data).then(response => {
        const avatar = response.data.key + '?v=' + Math.floor(Math.random(100000) * Math.floor(999999))
        state.profile.avatar = avatar
        commit('SET_PROFILE', state.profile)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateSchool({ commit }, userInfo) {
    const { name, cityId, districtId, level, _id } = userInfo
    return new Promise((resolve, reject) => {
      updateSchool({ name, cityId, districtId, level, _id }).then(response => {
        state.profile.school.name = name
        commit('SET_PROFILE', state.profile)
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  },

  updateLogo({ commit }, data) {
    return new Promise((resolve, reject) => {
      changeLogo(data).then(response => {
        const logo = response.data.key + '?v=' + Math.floor(Math.random(100000) * Math.floor(999999))
        console.log('===========START============')
        console.log(logo)
        console.log(_.cloneDeep(state.profile))
        console.log('===========MID============')
        state.profile.school.logo = logo
        commit('SET_PROFILE', state.profile)
        console.log(_.cloneDeep(state.profile))
        console.log('===========END============')
        resolve()
      }).catch(error => {
        reject(error)
      })
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import request from '@/utils/request'
import serialize from '@/utils/serialize'

export function getLiveExam(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/detail`,
    method: 'get'
  })
}

export function getLiveExamResult(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/result`,
    method: 'get'
  })
}

export function getLiveExamQuestionSubmission(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/question-submission` + serialize(data),
    method: 'get'
  })
}

export function getLiveExamQuestionResult(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/question-result` + serialize(data),
    method: 'get'
  })
}

export function startLiveExam(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/start`,
    method: 'post'
  })
}

export function endLiveExam(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/end`,
    method: 'post'
  })
}

export function goToNextQuestion(data) {
  return request({
    url: `/live-exam/live/${data.liveExamId}/next-question`,
    method: 'post'
  })
}

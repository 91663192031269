var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tinymce-container editor-container",class:{ fullscreen:_vm.fullscreen }},[_c('Editor',{attrs:{"id":_vm.id,"initial-value":_vm.value,"init":{
      plugins: _vm.plugins,
      toolbar: _vm.toolbar,
      menubar: _vm.menubar,
      height: _vm.height,
      forced_root_block: false,
      rootBlock: false,
      end_container_on_empty_block: true,
      powerpaste_word_import: 'clean',
      code_dialog_height: 450,
      code_dialog_width: 1000,
      advlist_bullet_styles: 'square',
      advlist_number_styles: 'default',
      default_link_target: '_blank',
      link_title: false,
      nonbreaking_force_tab: true,
      paste_data_images: false,
      paste_as_text: true,
      powerpaste_word_import: 'clean',
      powerpaste_html_import: 'clean',
      powerpaste_allow_local_images: false
    }},on:{"onChange":_vm.handlerChange}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }
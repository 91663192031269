<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    label: {
      type: String,
      default: null
    }
  }
}
</script>

<template>
  <div
    class="base-textarea"
    style="
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    "
  >
    <div
      v-if="label"
      class='input-label'
      style="
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
      "
    >
      <label
        style="
          color: #333;
          font-weight: bold;
          position: relative;
        "
      >
        {{ label }}
      </label>
    </div>

    <md-field>
      <md-textarea
        v-bind="$attrs"
        v-on="$listeners"
        @input="$emit('update', $event)"
      />
    </md-field>
  </div>
</template>

<style lang="scss">
  .base-textarea {
    .md-field.md-has-textarea:not(.md-autogrow):after, .md-field.md-has-textarea:not(.md-autogrow):before {
      box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
      border: 1px solid transparent !important;
    }
  }
</style>

import * as API from '@/api/mentor-house'
import { grantEasyMentorAccessToken } from '@/api/user'

const state = {
  house: null,
  mentors: [],
  easyMentorAccessToken: null
}

const mutations = {
  SET_HOUSE: (state, data) => {
    state.house = data
  },

  SET_MENTORS: (state, data) => {
    state.mentors = data
  },

  ADD_MENTOR: (state, data) => {
    state.mentors.push(data)
  },

  UPDATE_MENTOR: (state, data) => {
    const index = state.mentors.findIndex(mentor => mentor.easyUserId === data.easyUserId)
    if (index !== -1) {
      state.mentors[index] = data
    }
  },

  SET_EASY_MENTOR_ACCESS_TOKEN: (state, data) => {
    state.easyMentorAccessToken = data
  }
}

const actions = {
  async createMentor({ commit }, data) {
    const response = await API.createMentor(state.easyMentorAccessToken, data)
    commit('ADD_MENTOR', response.data)
  },

  async updateMentor({ commit }, data) {
    await API.updateMentor(state.easyMentorAccessToken, data)
    commit('UPDATE_MENTOR', data)
  },

  async deleteMentor({ commit }, data) {
    await API.deleteMentor(state.easyMentorAccessToken, data)
    commit('SET_MENTORS', state.mentors.filter(mentor => mentor.easyUserId !== data.easyUserId))
  },

  async getMyHouse({ commit }) {
    const response = await API.getMyHouse(state.easyMentorAccessToken)
    commit('SET_HOUSE', response.data)
  },

  async getMentors({ commit }) {
    const response = await API.getMentors(state.easyMentorAccessToken)
    commit('SET_MENTORS', response.data)
  },

  async updateHouse({ commit }, data) {
    const response = await API.updateHouse(data)
    commit('SET_HOUSE', response.data)
  },

  async grantEasyMentorAccessToken({ commit }) {
    const response = await grantEasyMentorAccessToken()
    commit('SET_EASY_MENTOR_ACCESS_TOKEN', response.data?.token)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

<script>
import { cloneDeep, isNil, get } from 'lodash'
import moment from 'moment'

export default {
  inheritAttrs: false,
  model: {
  },
  props: {
    exportExcelTemplate: {
      type: Object,
      default: () => {}
    },
    list: {
      type: Array,
      default: () => []
    },
    fileName: {
      type: String,
      default: 'export'
    },
    addDate: {
      type: String,
      default: null
    },
    height: {
      type: String,
      default: '42px'
    },
    cssClass: {
      type: String,
      default: 'el-icon-download'
    },
    type: {
      type: String,
      default: 'primary'
    }
  },
  methods: {
    handleExportExcel() {
      import('@/vendor/Export2Excel').then(excel => {
        const header = this.exportExcelTemplate.header
        const list = cloneDeep(this.list)
        if (this.exportExcelTemplate.arrJson && this.exportExcelTemplate.arrJson.length > 0) {
          list.forEach(item => {
            this.exportExcelTemplate.arrJson.forEach(field => {
              if (item[field]) {
                item[field] = JSON.stringify(item[field])
              }
            })
          })
        }
        const data = this.formatJson(this.exportExcelTemplate.filterVal, list)
        let fileName = this.fileName
        if (this.addDate && this.addDate !== '') {
          fileName += '_' + moment(new Date()).format(this.addDate)
        }
        excel.export_json_to_excel({
          header,
          data,
          filename: fileName,
          autoWidth: true,
          bookType: 'xlsx'
        })
      })
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => {
        const xj = j.split('@')
        let val = get(v, xj[0])

        if (isNil(val)) {
          return xj[1] === 'number' ? 0 : ''
        }

        if (xj.length > 0) {
          switch (xj[1]) {
            case 'dateTime':
              val = moment(val).format('hh:mm DD-MM-YYYY')
              break
            case 'date':
              val = moment(val).format('DD-MM-YYYY')
              break
          }
        }

        return val
      }))
    }
  }
}
</script>

<template>
  <el-button
        size="mini"
        :class="cssClass"
        :type="type"
        @click="handleExportExcel"
        :style="'height:' + height"
      >
      </el-button>
</template>

<style lang="scss">
.base-export-excel{
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

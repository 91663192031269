import serialize from '@/utils/serialize'
import axios from 'axios'
// import get from 'lodash/get'
// import request from '@/utils/request'

// import { Notification } from 'element-ui'

// create an axios instance
const houseRequest = axios.create({
  baseURL: process.env.VUE_APP_MENTOR_HOUSE_BASE_API,
  timeout: 10000
})

// response interceptor
houseRequest.interceptors.response.use(
  response => {
    return response
  },
  error => {
    return Promise.reject(error)
  }
)

export function getMyHouse(accessToken) {
  return houseRequest({
    url: '/mentor/houses/my',
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getHouseRankingNow(accessToken) {
  return houseRequest({
    url: '/statistics/house-ranking/now',
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function updateHouse(data) {
  return houseRequest({
    url: '/houses',
    method: 'patch',
    data
  })
}

export function getMentors(accessToken) {
  return houseRequest({
    url: '/mentor/mentors',
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function createMentor(accessToken, data) {
  return houseRequest({
    url: '/mentor/mentors',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function updateMentor(accessToken, data) {
  return houseRequest({
    url: `/mentor/mentors/${data.easyUserId}`,
    method: 'put',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function deleteMentor(accessToken, data) {
  return houseRequest({
    url: `/mentor/mentors/${data.easyUserId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getMembers(accessToken, query) {
  return houseRequest({
    url: '/mentor/members' + serialize(query),
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function deleteMember(accessToken, data) {
  return houseRequest({
    url: `/mentor/members?memberUserId=${data.easyUserId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getEasyClassMembership(accessToken) {
  return houseRequest({
    url: '/mentor/membership-rewards/memberships',
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getMembershipRewards(accessToken) {
  return houseRequest({
    url: '/mentor/membership-rewards',
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function createOrUpdateRewards(accessToken, data) {
  return houseRequest({
    url: '/mentor/membership-rewards',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function deleteRewards(accessToken, data) {
  return houseRequest({
    url: `/mentor/membership-rewards?membershipId=${data.membershipId}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getNews(accessToken, query) {
  return houseRequest({
    url: '/mentor/news-board' + serialize(query),
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getNewsDetail(accessToken, newsId) {
  return houseRequest({
    url: `/mentor/news-board/detail?newsBoardId=${newsId}`,
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function createNews(accessToken, data) {
  return houseRequest({
    url: '/mentor/news-board',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function updateNews(accessToken, data) {
  return houseRequest({
    url: `/mentor/news-board/${data._id}`,
    method: 'put',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function deleteNews(accessToken, data) {
  return houseRequest({
    url: `/mentor/news-board/${data._id}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function getNewsComments(accessToken, query) {
  return houseRequest({
    url: `/mentor/news-board/comments` + serialize(query),
    method: 'get',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function createNewsComment(accessToken, data) {
  return houseRequest({
    url: '/mentor/news-board/comments',
    method: 'post',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function updateNewsComment(accessToken, data) {
  return houseRequest({
    url: `/mentor/news-board/comments/${data._id}`,
    method: 'put',
    data,
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

export function deleteNewsComment(accessToken, data) {
  return houseRequest({
    url: `/mentor/news-board/comments/${data._id}`,
    method: 'delete',
    headers: {
      Authorization: `Bearer ${accessToken}`
    }
  })
}

/**
 * @param {string} path
 * @returns {Boolean}
 */
export function checkIsDevice(device) {
  const isMobile = {
    Android: () => {
      return navigator.userAgent.match(/Android/i)
    },
    BlackBerry: () => {
      return navigator.userAgent.match(/BlackBerry/i)
    },
    iOS: () => {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i)
    },
    iPad: () => {
      return navigator.userAgent.match(/iPad/i)
    },
    Opera: () => {
      return navigator.userAgent.match(/Opera Mini/i)
    },
    Windows: () => {
      return navigator.userAgent.match(/IEMobile/i)
    },
    any: () => {
      return (
        isMobile.Android() ||
        isMobile.BlackBerry() ||
        isMobile.iOS() ||
        isMobile.Opera() ||
        isMobile.Windows()
      )
    }
  }
  return isMobile[device]()
}

export function isApp() {
  if (document.location.protocol === 'file:') {
    return true
  } else {
    return false
  }
}

export function isInReview() {
  return false
}

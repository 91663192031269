<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3
    },
    totalPages: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    firstItem: {
      type: Number,
      required: true
    },
    lastItem: {
      type: Number,
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  computed: {
    startPage() {
      // When on the first page
      if (this.currentPage === 1) {
        return 1
      }
      // When on the last page
      if (this.currentPage === this.totalPages) {
        return this.totalPages - this.maxVisibleButtons + 1
      }
      // When in between
      return this.currentPage - 1
    },
    pages() {
      const range = []

      for (let i = this.startPage;
        i <= Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages);
        i++) {
        range.push({
          name: i,
          isDisabled: i === this.currentPage
        })
      }
      return range
    },
    isInFirstPage() {
      return this.currentPage === 1
    },
    isInLastPage() {
      return this.currentPage === this.totalPages
    }
  },
  methods: {
    onClickFirstPage() {
      this.$emit('pagechanged', 1)
    },
    onClickPreviousPage() {
      this.$emit('pagechanged', this.currentPage - 1)
    },
    onClickPage(page) {
      this.$emit('pagechanged', page)
    },
    onClickNextPage() {
      this.$emit('pagechanged', this.currentPage + 1)
    },
    onClickLastPage() {
      this.$emit('pagechanged', this.totalPages)
    },
    isPageActive(page) {
      return this.currentPage === page
    }
  }
}
</script>

<template>
  <div class="base-pagination">
    <div class="pagination-display" style="float: left">
      {{firstItem}}-{{lastItem}} {{$t('PAGNI_OF')}} {{total}}
    </div>
    <ul class="pagination" style="float: right">
      <!-- <li class="pagination-item">
        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
          First
        </button>
      </li> -->

      <li class="pagination-item">
        <button type="button" @click="onClickPreviousPage" :disabled="isInFirstPage" :style="isInFirstPage ? 'cursor: not-allowed; opacity: 0.2' : ''">
          <md-icon>chevron_left</md-icon>
        </button>
      </li>
      <li class="pagination-item" v-if="pages[0].name > 1">
        <button type="button" @click="onClickFirstPage" :disabled="isInFirstPage">
          1
        </button>
      </li>
      <li class="pagination-item" v-if="pages[0].name > 1"><md-icon>more_horiz</md-icon></li>
      <li v-for="page in pages" :key="page.name" class="pagination-item">
        <button v-if="page.name > 0" type="button" @click="onClickPage(page.name)" :disabled="page.isDisabled" :class="{ active: isPageActive(page.name) }">
          {{ page.name }}
        </button>
      </li>
      <li class="pagination-item" v-if="pages[pages.length - 1].name != totalPages"><md-icon>more_horiz</md-icon></li>
      <li class="pagination-item" v-if="pages[pages.length - 1].name != totalPages">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
          {{totalPages}}
        </button>
      </li>
      <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage"  :style="isInLastPage ? 'cursor: not-allowed; opacity: 0.2' : ''">
          <md-icon>chevron_right</md-icon>
        </button>
      </li>

      <!-- <li class="pagination-item">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
          Last
        </button>
      </li> -->
    </ul>
  </div>
</template>

<style lang="scss">
.base-pagination {
  width: 100%;
  background-color: #c8f4cf99;
  border-radius: 50px;
  white-space: nowrap;
  height: 35px;
  display: flex;

  .pagination-display {
    line-height: 35px;
    text-align: left;
    padding-left: 30px;
    opacity: 0.5;
    font-size: 16px;
    font-weight: 600;
  }

  .pagination {
    list-style-type: none;
    line-height: 35px;
    margin: 0px;
    text-align: right;
    padding-right: 30px;

    .pagination-item {
      display: inline-block;

      button {
        border: none;
        font-weight: bold;
        font-size: 16px;
        border-radius: 50%;
        background-color: #c8f4cf99;
        opacity: 0.6;
        cursor: pointer;
      }
    }

    .active {
      background-color: #1CAB36 !important;
      color: #ffffff;
    }
  }
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

<template>
  <div class="locale-changer">
    <el-popover placement="bottom" trigger="click" v-model= "visible">
      <md-list class="locale-list">
        <md-list-item v-for="(lang, i) in langs" :key="`Lang${i}`" @click="langChanged(lang)">
          <img :src="flags[lang]" :alt="labels[lang]"><span>{{ labels[lang] }}</span>
        </md-list-item>
      </md-list>
      <md-toolbar slot="reference" class="locale-display md-transparent" md-elevation="0" style="min-height: 0px">
        <div class="home-avatar">
          <img :src="flags[$i18n.locale]" :alt="labels[$i18n.locale]">
        </div>
        <div class="main">{{ labels[$i18n.locale] }}</div>
      </md-toolbar>
    </el-popover>
  </div>
</template>

<script>
import viFlag from '@/assets/vi.png'
import enFlag from '@/assets/en.png'

export default {
  name: 'LocaleChanger',
  data() {
    return {
      langs: ['vi', 'en'],
      labels: {
        vi: 'Tiếng Việt',
        en: 'English'
      },
      flags: {
        vi: viFlag,
        en: enFlag
      },
      visible: false
    }
  },
  methods: {
    langChanged(lang) {
      this.$i18n.locale = lang
      localStorage.setItem('lang', this.$i18n.locale)
      this.visible = false
    }
  },
  created() {}
}
</script>

<style lang="scss">
.locale-changer {
  bottom: 20px;
  right: 20px;

  // img {
  //   width: 35px;
  // }
  .locale-display {
    padding: 0;

    .home-avatar {
      margin-right: 26px;
    }

  }

}

.locale-list {
  img {
    width: 35px;
  }
}
</style>

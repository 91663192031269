<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    value: {
      type: [Array, Number, String, Object],
      default: () => null
    },
    file: {
      type: [Object, String],
      default: () => null
    },
    label: {
      type: String,
      default: null
    },
    labelSize: {
      type: Number,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    loading: {
      type: Boolean,
      default: false
    },
    progress: {
      type: Number,
      default: 0
    },
    placeholder: {
      type: String,
      default: ''
    },
    iconClass: {
      type: String,
      default: 'image'
    }
  },
  data() {
    return {
      temp: ''
    }
  },
  watch: {
    value: {
      handler(val) {
        this.temp = val
      },
      immediate: true
    }
  },
  mounted() {},
  methods: {
    handleChange(value) {
      this.$emit('update', value)
    },
    async handleUpload() {
      this.$refs.file.click()
    }
  }
}
</script>

<template>
  <div
    class="base-input-file"
  >
    <md-field
      style="
        position: relative;
        width: 100%;
        display: flex;
        flex-wrap: wrap;
      "
    >
      <div
        v-if="label"
        class='input-label'
        style="
          text-align: left;
          margin-bottom: 8px;
          width: 100%;
        "
      >
        <label
          style="
            color: #333;
            font-weight: bold;
            position: relative;
          "
          :style="'font-size: ' + (labelSize ? labelSize : 14) + 'px'"
        >
          {{ label }}
        </label>
      </div>

      <input type="file" :accept="accept" ref="file" style="display: none" @change="$emit('file-selected', $refs.file.files)">

      <el-input
        :value="value"
        v-on="$listeners"
        @change="handleChange"
      >
        <el-button v-loading="loading" slot="prepend" @click="handleUpload">
          <svg-icon v-if="iconClass" :iconClass="iconClass" />
        </el-button>
        <el-button slot="append" @click.native="$emit('clear', $event)">
          <md-icon v-if="!loading">close</md-icon>
          <span v-else>
            <el-progress type="circle" :percentage="progress" status="success" :width="32" />
          </span>
        </el-button>
      </el-input>
    </md-field>
  </div>
</template>

<style lang="scss">
.picker-dialog-frame {
  height: 100% !important;
}
.base-input-file {
  .input-label label {
    left: initial;
    top: initial;
  }

  .md-file {
    background-color: #fff !important;
    box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
    border: 1px solid transparent !important;
    margin: 0 !important;
    width: 100% !important;
    height: 42px !important;
  }
  input {
    height: 42px !important;
  }
  .md-field:after {
    height: 0 !important;
  }
  .md-field:before {
    height: 0 !important;
  }
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

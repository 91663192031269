<script>
export default {
  inheritAttrs: false,
  model: {
    event: 'update'
  },
  props: {
    type: {
      type: String,
      default: 'datetime'
    },
    label: {
      type: String,
      default: null
    },
    labelSize: {
      type: Number,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    customStyle: {
      type: String,
      default: ''
    },
    baseStyle: {
      type: String,
      default: ''
    }
  }
}
</script>

<template>
  <div
    class="base-date-question-picker"
    style="
      position: relative;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
    "
    :style="baseStyle"
  >
    <div
      v-if="label"
      class='input-label'
      style="
        text-align: left;
        margin-bottom: 8px;
        width: 100%;
      "
    >
      <label
        style="
          color: #333;
          font-weight: bold;
          position: relative;
        "
        :style="'font-size: ' + (labelSize ? labelSize : 14) + 'px'"
      >
        {{ label }}
      </label>
    </div>
    <el-date-picker
      :type="type"
      :placeholder="placeholder"
      v-bind="$attrs"
      v-on="$listeners"
      @input="$emit('update', $event)"
    />
  </div>
</template>

<style lang="scss">
.base-date-question-picker {
  .el-input {
    // background-color: rgba(0, 0, 0, 0.05) !important;
    // box-shadow: 0 8px 20px rgba($color: #000000, $alpha: 0.16) !important;
    border: 1px solid transparent !important;
    margin: 0 !important;
    width: 100% !important;
    height: 42px !important;
  }

  input {
    border: none;
    background-color: rgba(0, 0, 0, 0.05) !important;
  }
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {

}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

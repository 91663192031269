<template>
  <div id="app">
    <component
      v-if="this.$route.meta && this.$route.meta.layout"
      :is="this.$route.meta.layout"
    >
      <router-view/>
    </component>
    <router-view v-else />
  </div>
</template>

<script>

export default {
  components: {
  },
  created() {
    this.$i18n.locale = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'vi'
  }
}
</script>

<style lang="scss">
.md-table {
  .md-table-content {
    max-width: 100%;
  }
}
.new-student-dialog {
  .md-dialog-title {
    button {
      display: none;
    }
  }
}
@media only screen and (max-width: 320px) {

}
@media only screen and (max-width: 480px) {

}
@media only screen and (max-width: 600px) {
  .new-student-dialog {
    .md-dialog-title {
      position: relative;
      button {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}
@media only screen and (max-width: 768px) {

}
@media only screen and (max-width: 900px) {

}
@media only screen and (max-width: 1024px) {

}
</style>

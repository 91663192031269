import request from '@/utils/request'

export function updateSchool(data) {
  return request({
    url: `/school/${data._id}`,
    method: 'put',
    data
  })
}

export function changeLogo(data) {
  const upload = new FormData()
  upload.append('file', data.file)
  return request({
    url: '/school/logo',
    method: 'put',
    timeout: false,
    data: upload,
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

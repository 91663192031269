import * as API from '@/api/t-rcv-contest'
import _ from 'lodash'

const state = {
  questions: [],
  totalPoint: 0,
  contests: [],
  participants: [],
  contest: null
}

const mutations = {
  SET_QUESTIONS: (state, data) => {
    state.questions = data.questions
    state.totalPoint = data.totalPoint
  },

  NEW_QUESTION: (state, data) => {
    state.questions.push(data)
    state.totalPoint += data.point
  },

  SET_TOTAL_POINT: (state, data) => {
    state.totalPoint = data
  },

  UPDATE_QUESTION: (state, data) => {
    let oldPoint = 0
    const newPoint = data.point
    const index = _.findIndex(state.questions, q => {
      if (q._id.toString() === data._id.toString()) {
        oldPoint = q.point
        return true
      }
    })

    if (index !== -1) {
      state.questions.splice(index, 1, data)
      state.totalPoint = state.totalPoint - oldPoint + newPoint
    }
  },

  DELETE_QUESTION: (state, data) => {
    let point = 0
    const index = _.findIndex(state.questions, q => {
      if (q._id.toString() === data._id.toString()) {
        point = q.point
        return true
      }
    })

    if (index !== -1) {
      state.questions.splice(index, 1)
      state.totalPoint -= point
    }
  },

  SET_CONTESTS: (state, data) => {
    state.contests = data
  },

  SET_CONTEST: (state, data) => {
    state.contest = data
  },

  UPDATE_CONTEST_SCORE_RAKING: (state, data) => {
    state.contest.isScoredAndRanked = true
  },

  UPDATE_CONTEST_STATUS: (state, data) => {
    state.totalPoint = data.totalPoint
    state.questionCount = data.questionCount
  },

  NEW_CONTEST: (state, data) => {
    state.contests.unshift(data)
  },

  UPDATE_CONTEST: (state, data) => {
    const index = _.findIndex(state.contests, c => {
      return c._id.toString() === data._id.toString()
    })

    if (index !== -1) {
      state.contests.splice(index, 1, data)
    }
  },

  DELETE_CONTEST: (state, data) => {
    const index = _.findIndex(state.contests, c => {
      return c._id.toString() === data.contestId
    })

    if (index !== -1) {
      state.contests.splice(index, 1)
    }
  },

  SET_CONTEST_PARTICIPANTS: (state, data) => {
    state.participants = data
  }
}

const actions = {
  async getParticipants({ commit }, data) {
    const res = await API.getParticipants(data)
    commit('SET_CONTEST_PARTICIPANTS', res.data.participants)
    return res
  },

  async getQuestions({ commit }, contestId) {
    const res = await API.listQuestion(contestId)
    commit('SET_QUESTIONS', res.data.exam)
  },

  async createQuestion({ commit }, data) {
    const res = await API.createQuestion(data)
    commit('NEW_QUESTION', res.data)
  },

  async updateQuestion({ commit }, data) {
    const res = await API.updateQuestion(data)
    commit('UPDATE_QUESTION', res.data)
  },

  async deleteQuestion({ commit }, data) {
    const res = await API.deleteQuestion(data)
    commit('DELETE_QUESTION', res.data)
  },

  async getContest({ commit }, query) {
    return new Promise((resolve, reject) => {
      API.getContest(query)
        .then(res => {
          commit('SET_CONTEST', res.data.contest)
        })
        .catch(reject)
    })
  },

  async getContests({ commit }, query) {
    return new Promise((resolve, reject) => {
      API.listContest(query)
        .then(res => {
          commit('SET_CONTESTS', res.data.contests)
          resolve(res)
        })
        .catch(reject)
    })
  },

  async createContest({ commit }, data) {
    const res = await API.createContest(data)
    commit('NEW_CONTEST', res.data.contest)
  },

  async updateContest({ commit }, data) {
    const res = await API.updateContest(data)
    commit('UPDATE_CONTEST', res.data.contest)
  },

  async deleteContest({ commit }, data) {
    await API.deleteContest(data)
    commit('DELETE_CONTEST', data)
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}

import router from './router'
import store from './store'
import NProgress from 'nprogress' // progress bar
import 'nprogress/nprogress.css'
import { getToken } from '@/utils/auth'

NProgress.configure({
  showSpinner: false
})

const whiteList = [
  '/login',
  '/auth-redirect',
  '/register'
]

router.beforeEach(async(to, from, next) => {
  NProgress.start()

  // Remove matching lines if exist
  store.dispatch('global/setMatchingDrawerLines', [])

  const hasToken = getToken()
  if (hasToken) {
    if (to.path === '/login') {
      next({ path: '/' })
      NProgress.done()
    } else {
      if (store.getters.profile) {
        return next()
      }

      try {
        await store.dispatch('user/getInfo')
        if (store.getters.profile && store.getters.profile.roles && !store.getters.profile.roles.includes('group_chief') && !store.getters.profile.roles.includes('department_chief')) {
          store.dispatch('content/getConfig')
        } else {
          await store.dispatch('content/getDepartment')
        }
        next({ to, replace: true })
        NProgress.done()
      } catch (error) {
        await store.dispatch('user/resetToken')
        next('/login')
        NProgress.done()
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next()
    } else {
      next('/login')
      NProgress.done()
    }
  }
})

router.afterEach(() => {
  // finish progress bar
  NProgress.done()
})
